<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <NuxtLayout>
    <HomeHero />
    <HomeAboutMe />
    <HomeWorkshops />
    <HomeConsultations />
    <HomeConferences />
    <HomeTutoring />
    <HomeUpcomingWorkshop />
  </NuxtLayout>
</template>
